import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ModalProps, RegistrationRequestErrors } from "../store/interface";
import { AppDispatch, RootState } from "../store";
import Swal from "sweetalert2";
import {
  registerRequest,
  updateRegistrationRequest,
} from "../store/registration/registrationSlice";
import Loader from "../components/Loader/Loader";

// const Modal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
//   if (!isOpen) return null;

//   return (
//     <div className="modal-overlay">
//       <div className="modal">
//         <div className="modal-content">
//           <span className="close" onClick={onClose}>&times;</span>
//           <p>Your Registration has been successfully submitted!</p>
//           <button onClick={onClose}>OK</button>
//         </div>
//       </div>
//     </div>
//   );
// };

function RegistrationScreen() {
  const genderOptions = ["Select Gender", "Male", "Female", "Other"];
  const signupData = useSelector((state: RootState) => state.signup);

  const registrationRequest = useSelector(
    (state: RootState) => state.registrationRequest
  );
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const [errors, setErrors] = useState<RegistrationRequestErrors>({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const oneNumberRegex = /[0-9]/;
  const oneUppercaseRegex = /[A-Z]/;
  const oneLowercaseRegex = /[a-z]/;
  const allowedSpecialCharRegex = /[!@#$%^&*]/;

  useEffect(() => {
    debugger;
    if (
      (signupData.email &&
        signupData.email !== null &&
        signupData.email.trim() !== "") ||
      (signupData.phoneNumber &&
        signupData.phoneNumber !== null &&
        signupData.phoneNumber.trim() !== "")
    ) {
      if (!signupData.isEmailVerified || !signupData.isPhoneVerified) {
        debugger;
        dispatch(
          updateRegistrationRequest({
            email: signupData.email,
            phoneNumber: signupData.phoneNumber,
          })
        );
      } else {
        Swal.fire({
          title: "Email or Mobile info is not verified",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/signup");
          }
        });
      }
    } else {
      Swal.fire({
        title: "Email or Mobile info is not found",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/signup");
        }
      });
    }
  }, []);

  const validate = (): boolean => {
    const _errors: RegistrationRequestErrors = {};

    if (
      registrationRequest.username === null ||
      registrationRequest.username.trim() === ""
    ) {
      _errors.username = "Username is required";
    } else if (registrationRequest.username.length < 4) {
      _errors.username = "Username should contain atleast 4 char";
    } else if (!oneNumberRegex.test(registrationRequest.username)) {
      _errors.username = "Username should contain atleast one number";
    }

    if (
      registrationRequest.password === null ||
      registrationRequest.password.trim() === ""
    ) {
      _errors.password = "Password is required";
    } else if (registrationRequest.password.length < 8) {
      _errors.password = "Password should contain atleast 8 char";
    } else if (!oneUppercaseRegex.test(registrationRequest.password)) {
      _errors.password = "Password should contain atleast one Uppercase";
    } else if (!oneLowercaseRegex.test(registrationRequest.password)) {
      _errors.password = "Password should contain atleast one Lowercase";
    } else if (!oneNumberRegex.test(registrationRequest.password)) {
      _errors.password = "Password should contain atleast one number";
    } else if (!oneNumberRegex.test(registrationRequest.password)) {
      _errors.password = "Password should contain atleast one number";
    } else if (!allowedSpecialCharRegex.test(registrationRequest.password)) {
      _errors.password =
        "Password should contain one of the special chars !@#$%^&*";
    } else if (
      registrationRequest.password !== registrationRequest.confirmPassword
    ) {
      _errors.confirmPassword = "Passwords do not match";
    }

    if (
      !registrationRequest.gender ||
      registrationRequest.gender === genderOptions[0]
    ) {
      _errors.gender = "Gender is required";
    }

    if (!registrationRequest.dob) {
      _errors.dob = "Date of Birth is required";
    }

    // if (!registrationRequest.instituteCode) {
    //   _errors.instituteCode = 'Institute Code is required';
    // }

    if (!registrationRequest.termsAccepted) {
      _errors.termsAccepted = "You must accept the terms and conditions";
    }
    setErrors(_errors);

    return Object.keys(_errors).length === 0;
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | any>
  ): void => {
    const { name, value, type, checked } = e.target;
    dispatch(
      updateRegistrationRequest({
        [name]: type === "checkbox" ? checked : value,
      })
    );
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (validate()) {
      dispatch(registerRequest({ reqData: registrationRequest, navigate }));
    }
  };

  // const closeModal = () => {
  //   setIsModalOpen(false);
  //   setFormData(initialFormData);
  //   navigate('/login');

  // };

  return (
    <div className="App">
      {registrationRequest.loading && <Loader />}
      <form className="glass registrationForm" onSubmit={handleSubmit}>
        <div className="form-group">
          <h2>New Account</h2>
        </div>
        <div className="form-group row">
          <div className="col-sm-6">
            <div className="form-group">
              <label>
                Username<span className="required-mark"></span>
              </label>
              <input
                type="text"
                name="username"
                value={registrationRequest.username}
                required
                onChange={handleChange}
              />
              {errors.username && <p className="error">{errors.username}</p>}
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-group">
              <label>
                Date of Birth<span className="required-mark"></span>
              </label>
              <input
                type="date"
                name="dob"
                value={registrationRequest.dob}
                required
                onChange={handleChange}
              />
              {errors.dob && <p className="error">{errors.dob}</p>}
            </div>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-sm-6">
            <div className="form-group">
              <label>
                Create Password<span className="required-mark"></span>
              </label>
              <input
                type="password"
                name="password"
                value={registrationRequest.password}
                required
                onChange={handleChange}
              />
              {errors.password && <p className="error">{errors.password}</p>}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label>
                Confirm Password<span className="required-mark"></span>
              </label>
              <input
                type="password"
                name="confirmPassword"
                value={registrationRequest.confirmPassword}
                required
                onChange={handleChange}
              />
              {errors.confirmPassword && (
                <p className="error">{errors.confirmPassword}</p>
              )}
            </div>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-sm-6">
            <div className="form-group">
              <label>
                Select your Gender<span className="required-mark"></span>
              </label>
              <select
                name="gender"
                value={registrationRequest.gender}
                required
                onChange={handleChange}
              >
                {genderOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              {errors.gender && <p className="error">{errors.gender}</p>}
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-group">
              <label>Institute Code</label>
              <input
                type="text"
                name="instituteCode"
                value={registrationRequest.instituteCode}
                onChange={handleChange}
              />
              {errors.instituteCode && (
                <p className="error">{errors.instituteCode}</p>
              )}
            </div>
          </div>
        </div>

        <div className=" form-group full-width">
          <label>Terms & Conditions</label>
          <div className="long-text">
            <p>
              Spam text message examples Not all spam text messages are scams,
              but they very often are. Scammers use a variety of stories to try
              to trick you. Common examples include: You've won a prize, a gift
              card, or a coupon that you need to redeem. You’re being offered a
              low or no interest credit card. You have been overcharged and are
              owed a refund, possibly from a government agency such as the IRS
              or HMRC. Your account has been deactivated for your protection,
              and you need to take steps to reactivate it. You can get help to
              pay off your student debt. There is negative information in your
              credit report which you can remove for a fee. Suspicious activity
              has been noticed on your account, and further action on your part
              is required. There’s a problem with your payment information – you
              need to take action. There’s a notification about a delivery
              package – perhaps asking you to rearrange a delivery slot or else
              pay a delivery charge to receive it. You’re notified about a
              purchase or transaction and told to reply if it wasn’t you. “Get
              rich quick” or “Be your own boss” type messages. Fake text
              messages often try to create a sense of urgency – for example, by
              claiming that ‘urgent action is required’ or ‘you only have two
              days to reply’. Typically, the messages ask you to disclose some
              personal information – such as your bank or card details or Social
              Security number – to claim the gift or pursue the offer. Or you
              may be asked to click on a link to learn more about the issue. The
              link then takes you to a fake website where, if you log in, the
              scammers can steal your login credentials. Other SMS spam may
              install malware on your phone, which steals your personal
              information without you realizing it.
            </p>
          </div>
        </div>

        <div className="form-group full-width terms">
          <label className="terms-label">
            <input
              type="checkbox"
              name="termsAccepted"
              checked={registrationRequest.termsAccepted}
              onChange={handleChange}
            />
            <span>I accept the terms & conditions</span>
            <span className="required-mark"></span>
          </label>
          {errors.termsAccepted && (
            <p className="error">{errors.termsAccepted}</p>
          )}
        </div>

        <button className="btn btn-primary" type="submit">
          Proceed
        </button>
      </form>
      {/* <Modal isOpen={isModalOpen} onClose={closeModal} /> */}
    </div>
  );
}

export default RegistrationScreen;
