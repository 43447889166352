import React, { useState, useRef, useEffect } from "react";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import CircularProgressBar from "../../../components/CircularProgressBar/CircularProgressBarProps";
import BarChart from "../../../components/BarChart/BarChart";
import "./InstituteHero.css";
const InstituteHero = () => {
  const [number, setNumber] = useState("");

  const [specialNumber, setSpecialNumber] = useState("");
  const [studentName, setStudentName] = useState("");
  const [className, setClassName] = useState("");
  const [message, setMessage] = useState("");
  const [connectWithExpertFeedback, setConnectWithExpertFeedback] =
    useState("");
  const feedbackTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleSpecialMonitoringAccess = (e: any) => {
    e.preventDefault();
    if (specialNumber.trim() === "" || specialNumber.length !== 10) {
      alert("Please enter your number");
      return;
    }
    if (className.trim() === "") {
      alert("Please enter your number");
      return;
    }
    if (className.trim() === "") {
      alert("Please enter your number");
      return;
    }
    if (message.trim() === "") {
      alert("Please enter your number");
      return;
    }
    setConnectWithExpertFeedback(
      "Thank you very much for providing your details. Our team will be in touch with you shortly."
    );
    if (feedbackTimeoutRef.current) {
      clearTimeout(feedbackTimeoutRef.current);
    }

    feedbackTimeoutRef.current = setTimeout(() => {
      setConnectWithExpertFeedback("");
    }, 5000);
    console.log(specialNumber, studentName, className, message);
  };

  const [currentPercentage, setCurrentPercentage] = useState(0);
  const averageMarks = 90;
  useEffect(() => {
    // Function to increment the percentage gradually
    const increment = () => {
      setCurrentPercentage((prev) => {
        if (prev < averageMarks) {
          return Math.min(prev + 1, averageMarks); // Increase by 1 each time until it reaches averageMarks
        } else {
          clearInterval(timer); // Stop the interval when we reach averageMarks
          return prev;
        }
      });
    };

    const timer = setInterval(increment, 200); // Set speed of animation (20 ms in this case)

    return () => clearInterval(timer); // Cleanup interval on component unmount
  }, [averageMarks]);
  return (
    <div>
      <Header />
      <div className="container mt-5">
        <div className=" institute-portal-section-contact pt-3">
          <div className="row">
            <div className="col-12 mt-5 mb-5">
              <h3 className=" d-flex justify-content-center">HELP DESK</h3>
            </div>
            <div className="col-md-6 col-12">
              <div className="help-desk-form  ">
                <div className="text-left w-100">
                  <label
                    htmlFor=""
                    style={{
                      whiteSpace: "normal", // Allows the text to wrap to the next line
                      wordWrap: "break-word", // Breaks long words if necessary
                    }}
                  >
                    What is the primary purpose of a help desk?
                  </label>
                  <select className="form-control" required>
                    <option value="">Select an option</option>
                    <option value="A">
                      To provide technical support and troubleshoot issues
                    </option>
                    <option value="B">
                      To handle marketing and promotional activities
                    </option>
                    <option value="C">
                      To manage human resources and employee benefits
                    </option>
                    <option value="D">
                      To oversee financial transactions and budgeting
                    </option>
                  </select>
                </div>

                <div className="form-group w-100">
                  <label
                    htmlFor=""
                    style={{
                      whiteSpace: "normal", // Allows the text to wrap to the next line
                      wordWrap: "break-word", // Breaks long words if necessary
                    }}
                  >
                    How would you categorize a request for password reset?
                    request for password reset?
                  </label>
                  <select id="question2" className="form-control" required>
                    <option value="">Select an option</option>
                    <option value="A">Technical Support</option>
                    <option value="B">Billing Inquiry</option>
                    <option value="C">Product Feedback</option>
                    <option value="D">General Information</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-6 help-desk-form col-12  justify-content-center">
              <div className="row justify-content-center">
                <div className="col-md-12  d-flex justify-content-center mb-5 ">
                  <img
                    className="d-none d-md-block m-5"
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCfH2K5swHHYY8TlvoZwrqI-M4tfX6HGJCgg&s"
                    alt=""
                  />
                </div>
                <div className="col-12 d-flex justify-content-center ">
                  <input
                    type="number"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    required
                    className="form-control  connect-expert-input w-75"
                  />
                </div>
                <div className="w-50 col-12">
                  <button className="connect-expert-button">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: "100px" }}></div>

        <div className=" institute-portal-section-contact just mb-5  mt-5">
          <div className="row">
            <h2 className="section-title mb-5"> Institute Performance Meter</h2>
          </div>

          <div className="row d-flex justify-content-center text-center ">
            <div className="card pb-5 piechart-GuardianPortal col-md-6 col-12 mb-3">
              <div className="circular-progress-bar w-100 ">
                <CircularProgressBar percentage={currentPercentage} />
              </div>
            </div>
            <div className="card graph-institute-portal col-md-6 col-11 mb-3">
              <div className=" w-100 py-5 my-5">
                <h5>Data</h5>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div style={{ height: "100px" }}></div>
          {/*SPECIAL MONITORING ACCESS start  */}
          <div>
            <div className="institute-portal-section-contact pt-3">
              <div className="col-12 mt-5 mb-5">
                <h3 className=" d-flex justify-content-center">
                  Special Monitoring Access
                </h3>
              </div>

              <div className="justify-content-center  m-md-3">
                <div className="card   m-md-5 ">
                  <div className="row  d-flex m-md-3 p-1 p-md-5  connect-expert-container">
                    {/* <p className="text-center text-bold ">
                      SPECIAL MONITORING ACCESS
                    </p> */}
                    <div className="col-12 col-lg-6 text-center mb-5">
                      <img
                        className="hero-special-image"
                        src="https://www.foqas.com/static/web/img/tech/techvector.png"
                        alt="event-image"
                      />
                    </div>

                    <div className="col-12 col-lg-6 mt-2 ">
                      <div
                        style={{
                          height: "330px",
                          width: "auto",
                          objectFit: "cover",
                        }}
                      >
                        <div className="row mb-2 ">
                          {/* <div className="col-12 col-md-3 connect-expert-label">
                            Mobile:
                          </div> */}
                          <div className="col-12 col-lg-9  w-100">
                            <input
                              type="number"
                              placeholder="Contact Number"
                              value={specialNumber}
                              onChange={(e) => setSpecialNumber(e.target.value)}
                              required
                              className="form-control  connect-expert-input"
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          {/* <div className="col-12 col-md-3  connect-expert-label">
                            Student Name:
                          </div> */}
                          <div className="col-12 col-lg-9  w-100">
                            <input
                              type="text"
                              placeholder="Student Name"
                              value={studentName}
                              onChange={(e) => setStudentName(e.target.value)}
                              required
                              className="form-control connect-expert-input"
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          {/* <div className="col-12 col-md-3  connect-expert-label">
                            Class:
                          </div> */}
                          <div className="col-12 col-lg-9  w-100">
                            <input
                              type="text"
                              placeholder="Class"
                              value={className}
                              onChange={(e) => setClassName(e.target.value)}
                              required
                              className="form-control connect-expert-input"
                            />
                          </div>
                        </div>

                        <div className="row ">
                          <div className="text-center  d-flex justify-content-center   mb-md-2 mt-0 mb-0 ">
                            <textarea
                              className="form-control w-md-100  connect-expert-textarea"
                              placeholder="Reason for special monitoring"
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                              required
                            ></textarea>
                          </div>
                        </div>

                        <button
                          className="w-100 connect-expert-button"
                          onClick={handleSpecialMonitoringAccess}
                        >
                          {/* handleSubmitCall */}
                          Access Special Monitoring
                        </button>

                        {connectWithExpertFeedback && (
                          <p className="alert alert-success">
                            {connectWithExpertFeedback}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*SPECIAL MONITORING ACCESS end  */}
        </div>
        <div>
          <div style={{ height: "100px" }}></div>
          <div>
            <div className=" GuardianPortal_section_contact pt-3 ">
              <div className="col-12 mt-5 mb-5">
                <h3 className=" d-flex justify-content-center">
                  OVERALL PERFORMANCE BAR
                </h3>
              </div>
              <div className="d-flex justify-content-center ">
                <div className="card d-flex justify-content-center">
                  <div className="card-body">
                    <h5>Progress Graph</h5>
                    <BarChart />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: "100px" }}></div>

      <Footer />
    </div>
  );
};

export default InstituteHero;
