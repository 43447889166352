import { useState, useRef } from "react";
import "./GuardianAngel.css";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";

const languageContent: any = {
  english: `Our institute is dedicated to providing world-class education and fostering innovation. With a focus on holistic development, we equip students with the skills and knowledge they need to excel in their careers and contribute positively to society.`,
  hindi: `हमारा संस्थान विश्व स्तरीय शिक्षा प्रदान करने और नवाचार को बढ़ावा देने के लिए समर्पित है। समग्र विकास पर ध्यान केंद्रित करते हुए, हम छात्रों को उन कौशलों और ज्ञान से सुसज्जित करते हैं जिनकी उन्हें अपने करियर में उत्कृष्टता प्राप्त करने और समाज में सकारात्मक योगदान देने की आवश्यकता होती है।`,
  spanish: `Nuestro instituto está dedicado a proporcionar una educación de clase mundial y fomentar la innovación. Con un enfoque en el desarrollo integral, capacitamos a los estudiantes con las habilidades y el conocimiento que necesitan para sobresalir en sus carreras y contribuir positivamente a la sociedad.`,
  french: `Notre institut est dédié à fournir une éducation de classe mondiale et à encourager l'innovation. En mettant l'accent sur le développement holistique, nous dotons les étudiants des compétences et des connaissances dont ils ont besoin pour exceller dans leurs carrières et contribuer positivement à la société.`,
};

const blockData = [
  {
    id: "1",
    block:
      "Great service is attentive, personalized, and proactive, consistently exceeding expectations and building lasting customer loyalty through meaningful, seamless interactions",
  },
  {
    id: "2",
    block:
      "Great service is attentive, personalized, and proactive, consistently exceeding expectations and building lasting customer loyalty through meaningful, seamless interactions",
  },
];

const GuardianAngel = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("english");
  const [name, setName] = useState("");
  const [parnumber, setParnumber] = useState("");
  const [message, setMessage] = useState("");
  const [connectwithexpertfeedback, setConnectwithexpertfeedback] =
    useState("");
  const expertFeedbackTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Notification state
  // const [notifications, setNotifications] = useState(blockData);

  const notifications = blockData;
  const [notificationsEnabled, setNotificationsEnabled] = useState(true);

  const handleLanguageChange = (language: any) => {
    setSelectedLanguage(language);
  };

  const toggleNotifications = () => {
    setNotificationsEnabled(!notificationsEnabled);
  };
  const Connectwithexpert = (e: any) => {
    e.preventDefault();
    if (parnumber.trim() === "" || parnumber.length !== 10) {
      alert("Please enter your number");
      return;
    }
    if (name.trim() === "" || name.length === 0) {
      alert("Please enter your name");
      return;
    }
    if (message.trim() === "" || message.length === 0) {
      alert("Please enter your message");
      return;
    }
    setConnectwithexpertfeedback(
      "Thank you very much for providing your details. Our team will be in touch with you shortly."
    );
    console.log(parnumber, name, message);

    if (expertFeedbackTimeoutRef.current) {
      clearTimeout(expertFeedbackTimeoutRef.current);
    }

    // Set a timeout to clear the expert feedback message after 100 seconds
    expertFeedbackTimeoutRef.current = setTimeout(() => {
      setConnectwithexpertfeedback("");
    }, 5000);
  };

  return (
    <div>
      <Header />

      <div className="mt-5"></div>
      <div className="container">
        {/* OverAll Condition */}
        <div className="row d-flex">
          <div className="col-md-9">
            <div>
              <p>{languageContent[selectedLanguage]}</p>
            </div>
          </div>
          <div className="guardianangel-dropdown col-md-3">
            <div>
              <div className="dropdown">
                <button
                  className="btn btn-primary dropdown-toggle"
                  type="button"
                  id="languageDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Language
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="languageDropdown"
                >
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => handleLanguageChange("hindi")}
                    >
                      Hindi
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => handleLanguageChange("english")}
                    >
                      English
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => handleLanguageChange("spanish")}
                    >
                      Spanish
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => handleLanguageChange("french")}
                    >
                      French
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Notification Section */}
        <div className="notification-section   guardianangel-dropdown-notification-section">
          <button
            className="btn btn-primary w-100 "
            onClick={toggleNotifications}
          >
            {notificationsEnabled
              ? "Disable Notifications"
              : "Enable Notifications"}
          </button>
          {notificationsEnabled && notifications.length > 0 && (
            <div className="notification-list w-100 ">
              <ul>
                {notifications.map((notification) => {
                  // Determine background color based on the id
                  const bgColor =
                    parseInt(notification.id) % 2 === 0 ? "green" : "gray";

                  return (
                    <li
                      className="m-2 "
                      key={notification.id}
                      style={{
                        backgroundColor: bgColor,
                        color: "white",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      {notification.block}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        {/* Progress alert notification */}
        <div className="mt-5 guardian-portal-section-contact  container m-3">
          <div className="col-12 mt-5 mb-5">
            <h3 className=" d-flex justify-content-center text-center">
              Access Special Monitoring of the Ward
            </h3>
          </div>
          <div className="card m-md-5 ">
            <div className="row d-flex  m-md-3 p-1 p-md-5   connect-expert-container">
              <div className="col-12 col-lg-6 text-center mb-5">
                <img
                  // className="w-md-100 w-100 connect-expert-img"
                  className="monitoring-image  lg-h-5 "
                  src="https://www.foqas.com/static/web/img/tech/techvector.png"
                  alt=""
                  // style={{
                  //   height: "250px",
                  //   maxWidth: "100%",
                  //   width: "auto",
                  //   objectFit: "cover",
                  // }}
                  // style={{ height: "250px", width: "auto" }}
                />
              </div>

              <div className="col-12 col-lg-6 mb-2">
                <div style={{ height: "320px", width: "auto" }}>
                  <div className="row mb-2">
                    {/* <div className="col-12 col-md-3 connect-expert-label">
                      Name:
                    </div> */}
                    <div className="col-12 col-lg-9  w-100">
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Name"
                        required
                        className="form-control connect-expert-input"
                      />
                    </div>
                  </div>

                  <div className="row mb-2">
                    {/* <div className="col-12 col-md-3 connect-expert-label">
                      Mobile:
                    </div> */}
                    <div className="col-12 col-lg-9  w-100">
                      <input
                        type="number"
                        placeholder="Contact no"
                        value={parnumber}
                        onChange={(e) => setParnumber(e.target.value)}
                        required
                        className="form-control  connect-expert-input"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="text-center d-flex justify-content-center  mb-md-2 mt-0 mb-0 ">
                      <textarea
                        className="form-control w-md-100  connect-expert-textarea"
                        placeholder="Reason for special monitoring"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                      ></textarea>
                    </div>
                  </div>

                  <button
                    className="w-100 connect-expert-button"
                    onClick={Connectwithexpert}
                  >
                    Special Ward Access
                  </button>

                  {connectwithexpertfeedback && (
                    <p className="alert alert-success">
                      {connectwithexpertfeedback}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default GuardianAngel;
