import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

// Register Chart.js components
Chart.register(...registerables);

interface BmiPieGraphProps {
  userWeight: number; // Weight in kilograms
}

const BmiPieGraph: React.FC<BmiPieGraphProps> = ({ userWeight }) => {
  const height = 1.65; // Fixed height in meters
  const bmi = userWeight / (height * height); // Calculate BMI

  // Determine the BMI category
  let category = "";
  if (bmi < 18.5) {
    category = "Underweight";
  } else if (bmi >= 18.5 && bmi < 25) {
    category = "Normal weight";
  } else if (bmi >= 25 && bmi < 30) {
    category = "Overweight";
  } else {
    category = "Obesity";
  }

  // Data for the pie chart
  const data = {
    labels: ["Underweight", "Normal weight", "Overweight", "Obesity"],
    datasets: [
      {
        data: [0, 0, 0, 0], // Initialize counts to zero
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)", // Underweight
          "rgba(54, 162, 235, 0.6)", // Normal weight
          "rgba(255, 206, 86, 0.6)", // Overweight
          "rgba(75, 192, 192, 0.6)", // Obesity
        ],
        borderWidth: 1,
      },
    ],
  };

  // Update the pie chart data based on the user's weight category
  if (category === "Underweight") {
    data.datasets[0].data[0] = userWeight; // Underweight
  } else if (category === "Normal weight") {
    data.datasets[0].data[1] = userWeight; // Normal weight
  } else if (category === "Overweight") {
    data.datasets[0].data[2] = userWeight; // Overweight
  } else if (category === "Obesity") {
    data.datasets[0].data[3] = userWeight; // Obesity
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false, // This allows the chart to expand to the height of its container
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "BMI Distribution",
      },
    },
  };

  return (
    <div style={{ width: "100%", height: "400px" }}>
      {/* Responsive container */}
      {/* <h2>BMI Pie Graph</h2> */}
      <Pie data={data} options={options} userWeight={userWeight} />
      {/* <h3>Your Weight: {userWeight} kg</h3> */}
      {/* <h3>Your BMI Category: {category}</h3> */}
    </div>
  );
};

export default BmiPieGraph;
