import React from "react";
import "./Sos.css";
const Sos: React.FC = () => {
  return (
    <div className="container ">
      <h1 className="">SOS Emergency </h1>
      <h4>Notification:</h4>
      <div className="Sospage">
        <div className="alert alert-success">hello</div>
        <div className="alert alert-success">hello</div>
      </div>
    </div>
  );
};

export default Sos;
