import React from "react";

const PhysicalHealth = () => {
  return (
    <div>
      <h1>PhysicalHealth</h1>
    </div>
  );
};

export default PhysicalHealth;
