import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
const TermsAndConditions = () => {
  return (
    <div>
      <Header />
      <div className="container">
        <div className="d-flex text-center justify-cotent-center">
          <h1 className=" mt-5 ">Terms and Conditions</h1>
        </div>
        <div className="md-mb-4 md-p-5">
          <ol>
            <li>
              <h5>Scope of Services:</h5>
            </li>
            <ul className="pb-3">
              <li>
                <h6>
                  The service provider agrees to offer mental health and
                  well-being services with a special focus on adolescents and
                  underprivileged individuals.
                </h6>
              </li>
              <li>
                <h6>
                  Services may include counseling, therapy, workshops, and other
                  relevant interventions.
                </h6>
              </li>
              <li>
                <h6>
                  Services will be structured to ensure equitable access for all
                  individuals, irrespective of socio-economic status, with a
                  commitment to reducing barriers to mental health support
                </h6>
              </li>
              <li>
                <h6>
                  Clients will be actively involved in decision-making processes
                  regarding their mental health, with the service and empowered
                  participation in their mental health journey.
                </h6>
              </li>
              <li>
                <h6>
                  Users can answer questionnaire by themselves and do
                  self-analysis about their mind and their mental state.
                </h6>
              </li>
              <li>
                <h6>
                  Users can create groups with like-minded people and express
                  their feelings with each other without disclosing their
                  identity.
                </h6>
              </li>
              <li>
                <h6>
                  You will be guided by counselors, therapists and
                  psychiatrists. Regarding your physical health and mental
                  health.
                </h6>
              </li>
              <li>
                <h6>
                  Panel will be made by the company so that, institute and
                  guardian can keep an eye on their children, look upon their
                  progress report, their results and understand their mental
                  state.
                </h6>
              </li>
              <li>
                <h6>
                  You can see your own self-progress report and work on what
                  changes are needed and not needed accordingly
                </h6>
              </li>
              <li>
                <h6>
                  You are also given the facility to maintain a digital diary
                  here where you can maintain your own record and note down your
                  thoughts.
                </h6>
              </li>
              <li>
                <h6>
                  Helpline numbers will be provided so that if something happens
                  to him/her ,then his/her current location will be known to
                  their parents, institute and near by police station and users
                  can contact appropriate helpline.
                </h6>
              </li>
            </ul>
            <li>
              <h5>Confidentiality and Legal Privilege:</h5>
            </li>
            <ul className="pb-3">
              <li>
                <h6>
                  All client information shared during sessions is strictly
                  confidential.
                </h6>
              </li>
              <li>
                <h6>
                  The service provider will adhere to all applicable laws and
                  regulations regarding client confidentiality.
                </h6>
              </li>
              <li>
                <h6>
                  The service provider acknowledges the legal duty of
                  confidentiality and will adhere to all applicable laws and
                  professional ethical standards in maintaining the privacy of
                  client information.
                </h6>
              </li>
              <li>
                <h6>
                  Legal privilege protections will be invoked where applicable
                  to safeguard confidential communications between the service
                  provider and the client.
                </h6>
              </li>
            </ul>
            <li>
              <h5>Informed Consent and Capacity:</h5>
            </li>
            <ul className="pb-3">
              <li>
                <h6>
                  Informed consent will be obtained from clients or their legal
                  guardians, ensuring that individuals possess the legal
                  capacity to consent to mental health services.
                </h6>
              </li>
              <li>
                <h6>
                  Legal guardians of adolescents will be actively involved in
                  the decision-making process, respecting legal requirements for
                  minors.
                </h6>
              </li>
              <li>
                <h6>
                  The Website allows to have access to registered Users’ email
                  or phone number, for communication purpose so as to provide
                  you with a better way of booking sessions and for obtaining
                  feedback in relation to the Practitioners and their practice
                </h6>
              </li>
              <li>
                <h6>
                  <li>
                    If you use this platform on behalf of someone else (such as
                    your child, minor, ward or employee) or an entity (such as
                    your employer), you represent that you are authorised by
                    such individual or entity to
                  </li>
                  <ul>
                    <li>
                      accept this privacy policy on such individual’s or
                      entity’s behalf, and
                    </li>
                    <li>
                      consent on behalf of such individual or entity to our
                      collection, use and disclosure of such individual’s or
                      entity’s information as described in this privacy policy.
                    </li>
                  </ul>
                </h6>
              </li>
              <li>
                <h6>
                  <li>
                    The User is expected to read and understand the Privacy
                    Policy, so as to ensure that he or she has the knowledge of,
                    every other similar things connected to each other
                  </li>
                  <ul className="pb-3">
                    <li>
                      the fact that certain information is being collected;
                    </li>
                    <li>
                      the purpose for which the information is being collected;
                    </li>
                    <li>the intended recipients of the information;</li>
                    <li>
                      the nature of collection and retention of the information;
                      and
                    </li>
                    <li>
                      the name and address of the agency that is collecting the
                      information and the agency that will retain the
                      information; and
                    </li>
                    <li>
                      the various rights available to such Users in respect of
                      such Information.
                    </li>
                  </ul>
                </h6>
              </li>
            </ul>
            <li>
              <h5>Cultural Sensitivity:</h5>
            </li>
            <ul className="pb-3">
              <li>
                <h6>
                  The service provider shall demonstrate cultural competence and
                  sensitivity, recognizing and respecting the diverse
                  backgrounds and experiences of clients.
                </h6>
              </li>
              <li>
                <h6>
                  The service provider will actively engage in collaborative
                  efforts with local communities, organizations, and advocates
                  to create a supportive network for mental health initiatives.
                </h6>
              </li>
            </ul>
            <li>
              <h5>Record Keeping and Data Protection</h5>
            </li>
            <ul className="pb-3">
              <li>
                <h6>
                  The service provider will maintain accurate and secure records
                  in compliance with relevant data protection laws.
                </h6>
              </li>
              <li>
                <h6>
                  Clients have the right to access their records in accordance
                  with applicable legal provisions.
                </h6>
              </li>
              <li>
                <h6>
                  If the company comes to know that the data is being infringed
                  by some user or if someone is being bullied, then it can
                  prohibit that person from using that platform and also stop
                  the people associated with it.
                </h6>
              </li>
              <li>
                <h6>
                  The service provider company can/ may use your certain private
                  data as per their requirement for public display.
                </h6>
              </li>
              <li>
                <h6>
                  If a User provides any information that is untrue, inaccurate,
                  not current or incomplete (or becomes untrue, inaccurate, not
                  current or incomplete), or My company has reasonable grounds
                  to suspect that such information is untrue, inaccurate, not
                  current or incomplete,then my company has the right to
                  discontinue the access of the My Platform to the User at its
                  sole discretion
                </h6>
              </li>
            </ul>
            <li>
              <h5>Accessibility</h5>
            </li>
            <ul className="pb-3">
              <li>
                <h6>
                  Efforts will be made to ensure services are accessible to
                  underprivileged individuals, including considerations for
                  affordability and flexibility in scheduling.
                </h6>
              </li>
              <li>
                <h6>
                  The Website allows to have access to registered Users’ email
                  or phone number, for communication purpose so as to provide
                  you with a better way of booking sessions and for obtaining
                  feedback in relation to the Practitioners and their practice.
                </h6>
              </li>
              <li>
                <h6>
                  My company assumes no responsibility, and shall not be liable
                  for, any damages to, or viruses that may infect User’s
                  equipment on account of User’s access to, use of, or browsing
                  the Website or the downloading of any material, data, text,
                  images, video content, or audio content from the Website. If a
                  User is dissatisfied with My company’s Platform, User’s sole
                  remedy is to discontinue using the My company’s Platform
                </h6>
              </li>
            </ul>
            <li>
              <h5>Professional Standards:</h5>
            </li>
            <ul className="pb-3">
              <li>
                <h6>
                  The service provider shall adhere to ethical standards and
                  practices as outlined by relevant mental health professional
                  organizations.
                </h6>
              </li>
              <li>
                <h6>
                  Continuous professional development is encouraged to stay
                  updated on the latest research and best practices in the
                  field.
                </h6>
              </li>
            </ul>
            <li>
              <h5>Collaboration:</h5>
            </li>
            <ul className="pb-3">
              <li>
                <h6>
                  The service provider may collaborate with other professionals,
                  organizations, or community resources to enhance the quality
                  and effectiveness of services.
                </h6>
              </li>
              <li>
                <h6>
                  If you decide to sign up for or link your "xyz" personal
                  account with our platform using services like Facebook or
                  Google, those are called Integrated Services. By doing this,
                  you're allowing us to access information from your Integrated
                  Service account. It's important to know that an Integrated
                  Service is like a reference site, and any interactions or
                  dealings you have with it are your responsibility. We don't
                  control how Integrated Services operate, so it's a good idea
                  to check their Privacy Policy and Terms of Use to understand
                  how they handle things.
                </h6>
              </li>
              <li>
                <h6>
                  Our website might have links to other websites owned by third
                  parties, affiliates, or business partners. We want to make it
                  clear that we don't have control over those external websites,
                  and we're not responsible for their content, accuracy,
                  reliability, or quality. Just because we have a link to
                  another site doesn't mean we endorse it. If you choose to use
                  these links or services from those sites, you're doing so at
                  your own risk.
                </h6>
              </li>
            </ul>
            <li>
              <h5>Cancellation and Rescheduling:</h5>
            </li>
            <ul className="pb-3">
              <li>
                <h6>
                  Clients are encouraged to provide advance notice for
                  cancellations or rescheduling of appointments.
                </h6>
              </li>
              <li>
                <h6>
                  The service provider reserves the right to charge a fee for
                  missed appointments without sufficient notice.
                </h6>
              </li>
              <li>
                <h6>
                  A minimum of 24 hours' notice is required for rescheduling or
                  cancellation of an appointment. Without such notification,
                  fees will be charged to the Users as follows:
                </h6>
                <ul>
                  <li>
                    <h6>
                      Cancellations within 24 hours of the appointment: 100% of
                      the fee payable for the session.
                    </h6>
                  </li>
                  <li>
                    <h6>
                      No‐shows (missed appointment without notification): 100%
                      of the fee payable for the session.
                    </h6>
                  </li>
                  <li>
                    <h6>
                      The Client may avoid a cancellation or no‐show fee if the
                      Parties are able to reschedule within the same week as
                      dictated by the Practitioner schedule and availability
                    </h6>
                  </li>
                </ul>
              </li>
              <li>
                <h6>
                  If the person you booked a paid session with through our
                  platform cancels or can't meet you, you should email us at
                  hello@Mycompany.co within a few days of that happening. In
                  this case, we will refund 100% of the fee you paid for the
                  session within the next few business days using the same
                  method you used for payment. Alternatively, you and the
                  practitioner can agree to reschedule the session, and the fee
                  you paid will be adjusted for that rescheduled session.
                </h6>
              </li>
              <li>
                <h6>
                  To cancel or reschedule a session the User shall do so by
                  logging into the My companiesPlatform and/or write an email at
                  (hello@mycompany.co), any notification or communication beyond
                  office hours (10 am to 6 pm) shall be treated to be made the
                  subsequent day
                </h6>
              </li>
              <li>
                <h6>
                  No Doctor-Patient Relationship; Not for Emergency Use. Please
                  note that this platform provision of such Information does not
                  create a licensed medical professional/patient relationship,
                  between My company and you and does not constitute an opinion,
                  medical advice, or diagnosis or treatment of any particular
                  condition, but is only provided to assist you with locating
                  appropriate mental health care services from a qualified
                  professional.
                </h6>
              </li>
              <li>
                <h6>
                  Simply put, the information you get from us, our employees,
                  partners, or anyone associated with our platform is for
                  informational purposes only. We don't make any promises or
                  guarantees about professional qualifications, the quality of
                  work, or expertise mentioned on the website. We won't be
                  responsible if you make decisions or take actions based on
                  this information. It's important to understand that the
                  information is provided as-is, and we're not liable for any
                  consequences of relying on it.
                </h6>
              </li>
              <li>
                <h6>
                  My company Platform is not for use in medical emergencies or
                  critical health situations requiring prompt medical attention.
                  If you are considering or contemplating suicide or feel that
                  you are a danger to yourself or others, you must immediately,
                  call 112, The Platform is not intended to be real-time and
                  does not deal in emergencies. If you think you have a medical
                  or mental health emergency, please call, or go to the nearest
                  open hospital or clinic or emergency services.
                </h6>
              </li>
            </ul>
            <li>
              <h5>Dispute Resolution and Legal Protections:</h5>
            </li>
            <ul className="pb-3">
              <li>
                <h6>
                  Disputes arising from these terms and conditions will be
                  resolved through legally recognized methods such as mediation
                  or arbitration
                </h6>
              </li>
              <li>
                <h6>
                  Legal protections will be actively invoked to safeguard the
                  interests and rights of both the service provider and the
                  clients.
                </h6>
              </li>
              <li>
                <h6>
                  Our company shall not be responsible in any manner for the
                  authenticity of the personal information or sensitive personal
                  data or information supplied by the User to our company or any
                  other person acting on behalf of my company.
                </h6>
              </li>
              <li>
                <h6>
                  If a court or arbitrator decides that any part of the
                  agreement cannot be enforced under the law, that specific part
                  will be removed, and the rest of the agreement will still be
                  valid and enforced as if that part was never there. However,
                  the agreement will be interpreted in a way that reflects the
                  original meaning and intention of the removed part to the
                  extent allowed by the law
                </h6>
              </li>
            </ul>
            <li>
              <h5>Payment and Fees:</h5>
            </li>
            <ul className="pb-3">
              <li>
                <h6>
                  Fees for services will be communicated transparently before
                  the commencement of services.
                </h6>
              </li>
              <li>
                <h6>
                  Sliding scale or discounted fees may be offered to
                  underprivileged individuals based on their financial
                  circumstances.
                </h6>
              </li>
            </ul>
            <li>
              <h5>Financial Transparency</h5>
            </li>
            <ul className="pb-3">
              <li>
                <h6>
                  Any fees or financial arrangements will be communicated
                  transparently and will adhere to applicable laws and
                  regulations.
                </h6>
              </li>
              <li>
                <h6>
                  Efforts will be made to provide affordable services, with
                  considerations for underprivileged individuals
                </h6>
              </li>
            </ul>
            <li>
              <h5>Feedback and Improvement:</h5>
            </li>
            <ul className="pb-3">
              <li>
                <h6>
                  Clients are encouraged to provide feedback on the services
                  received
                </h6>
              </li>
              <li>
                <h6>
                  The service provider is committed to continuous improvement
                  and may use feedback to enhance the quality of services.
                </h6>
              </li>
              <li>
                <h6>
                  actively seeking and valuing client feedback, using this input
                  to continually improve and adapt services to better meet the
                  needs of the target population
                </h6>
              </li>
            </ul>
            <li>
              <h5>Termination of Services:</h5>
            </li>
            <ul className="pb-3">
              <li>
                <h6>
                  The service provider reserves the right to terminate services
                  if it is determined to be in the best interest of the client
                  or if ethical standards are compromised.
                </h6>
              </li>
              <li>
                <h6>
                  Transparent and open communication will be maintained with
                  clients regarding the nature of services, potential risks, and
                  any relevant information, ensuring informed consent and active
                  participation.
                </h6>
              </li>
              <li>
                <h6>
                  The service provider can modify or change the Terms and
                  conditions according to them without giving any prior
                  information or consent.
                </h6>
              </li>
            </ul>
            <li>
              <h5>Situations</h5>
            </li>
            <ul className="pb-3">
              <li>
                <h6>
                  In the case of emergencies, the service provider will follow
                  appropriate protocols, which may include contacting emergency
                  services or designating emergency contacts.
                </h6>
              </li>
            </ul>
            <li>
              <h5>Legal Compliance:</h5>
            </li>
            <ul className="pb-3">
              <li>
                <h6>
                  The service provider commits to full compliance with all
                  relevant local, state, and federal laws governing the
                  provision of mental health services, with a specific focus on
                  regulations related to services for adolescents and
                  underprivileged individuals
                </h6>
              </li>
            </ul>
            <li>
              <h5>Dispute Resolution:</h5>
            </li>
            <ul className="pb-3">
              <li>
                <h6>
                  In the event of a dispute, both parties agree to engage in
                  good faith efforts to resolve the matter amicably.
                </h6>
              </li>
              <li>
                <h6>
                  You agree that this Agreement and any contractual obligation
                  between our company and User will be governed by the laws of
                  India.
                </h6>
              </li>
              <li>
                <h6>
                  Any dispute, claim, or controversy arising out of or relating
                  to this Agreement, including the determination of the scope or
                  applicability of this Agreement to arbitrate, or your use of
                  the Website or the My company Platform or information to which
                  it gives access, shall be determined by arbitration in India,
                  before a sole the arbitrator appointed by our company. The
                  arbitration shall be conducted in accordance with the
                  Arbitration and Conciliation Act, 1996 (as amended from time
                  to time.
                </h6>
              </li>
              <li>
                <h6>
                  In the event of legal actions or the initiation of any legal
                  proceedings against our country, such matters shall
                  exclusively be pursued within the jurisdiction of our local
                  court. No legal proceedings may be conducted in any other
                  court, irrespective of the circumstances.
                </h6>
              </li>
            </ul>
            <li>
              <h5>Modification of Terms:</h5>
            </li>

            <ul className="pb-3">
              <li>
                Any modifications to these terms and conditions must be
                documented in writing. By engaging in mental health and
                well-being services provided under these terms and conditions,
                both clients and the service provider acknowledge and agree to
                comply with the legal obligations and ethical standards outlined
                herein.
              </li>
            </ul>
            <li className="">
              <h5>Booking a Consultation:</h5>
            </li>
            <ul className="pb-5">
              <li>
                <h6>
                  My company enables Users to connect with Practitioners through
                </h6>
                <ul>
                  <li>
                    <h6>
                      Book Now facility that allows Users book a Consultation
                      through MyPlatform.
                    </h6>
                  </li>
                  <li>
                    <h6>
                      Scheduling a Consultation over a phone call or SMS or
                      WhatsApp.
                    </h6>
                  </li>
                  <li>
                    <h6>
                      Tele-consultation services (chat, audio and video) which
                      connect Users directly to the Practitioners through my
                      platform.
                    </h6>
                  </li>
                </ul>
              </li>
              <li>
                <h6>
                  If a User has scheduled the consultation over a phone call or
                  SMS or WhatsApp, and/or availed tele-consultation
                  services(chat, audio or video), My company reserves the right
                  to share the information provided by the User with the
                  Practitioner and store such information and/or
                  tele-consultation of the User with the Practitioners, in
                  accordance with our Privacy Policy.
                </h6>
              </li>
              <li>
                <h6>
                  In simple terms, our company is just making it easier for you
                  to connect with healthcare practitioners, but we're not
                  involved in giving medical advice or diagnoses. We're not
                  responsible for any issues or interactions you have with the
                  practitioners. This includes problems like the practitioner
                  not fulfilling their responsibilities, giving the wrong
                  treatment, or any kind of negligence. We're also not
                  responsible for any misconduct or inappropriate behavior from
                  the practitioners or their staff. If a practitioner cancels,
                  doesn't show up, or changes fees (unless covered by our
                  Guarantee Program), we're not liable for that either. It's
                  important to understand that we're acting as a middleman, and
                  the actual healthcare services are between you and the
                  practitioner.
                </h6>
              </li>
              <li>
                <h6>
                  All End Users, as well as the Practitioner cancellations, no
                  show, rescheduling, shall be strictly dealt with My companies
                  rescheduling, refund & cancellation Policy.
                </h6>
              </li>
              <li>
                <h6>
                  Users are allowed to provide feedback about their experiences
                  with the Practitioner.
                </h6>
              </li>
            </ul>
            <li>
              <h5>SPECIFIC TERMS:</h5>
            </li>
            <h6 className="pb-5">
              Users must follow extra rules or terms specific to certain
              products, features, incentives, subscriptions, or usage policies.
              These additional rules, called "Specific Terms," will be posted
              from time to time. You are automatically agreeing to follow these
              Specific Terms when you agree to the main terms.
            </h6>
            <li>
              <h5>RETENTION AND REMOVAL:</h5>
            </li>
            <h6 className="pb-5">
              My company may retain such information collected from Users from
              its Website or My companies Platform for as long as necessary,
              depending on the type of information; purpose, means, and modes of
              usage of such information; and according to the SPI Rules.
              Computer web server logs may be preserved as long as
              administratively necessary.
            </h6>
            <li>
              <h5>WAIVER:</h5>
            </li>
            <h6 className="pb-3">
              If there's a rule in this agreement, we won't consider it ignored
              or forgiven unless it's specifically written down and signed by my
              company. If my company overlooks or forgives one mistake you make,
              it doesn't mean we're letting go of any other mistakes you might
              make later on.
            </h6>
          </ol>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
