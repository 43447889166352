// import React from "react";
// import HomeBanner from "../../components/HomeBanner/HomeBanner";
// import Header from "../../components/header/Header";
// import Footer from "../../components/footer/Footer";
// import GetinTouchWithUs from "../../components/getInTouchWithUs/GetinTouchWithUs";
// import "./LeaderBoardScreennew.css";
// import img1 from "../../assets/images/womanInDepression.png";
// import img2 from "../../assets/images/womanInThoughtfulPose.png";
// import img3 from "../../assets/images/womanSittingAndPointing.png";
// const LeaderBoardData = [
//   {
//     _id: "1",
//     link: "/nationalstanding",
//     metaldisorderImage: img1,
//     mentaldisorderName: "National Standing ",
//   },
//   {
//     _id: "2",
//     link: "/zonalStanding",
//     metaldisorderImage: img2,
//     mentaldisorderName: "Zonal Standing ",
//   },
//   {
//     _id: "3",
//     link: "/institutionalStanding",
//     metaldisorderImage: img3,
//     mentaldisorderName: "Institutional  Standing ",
//   },
// ];
// const LeaderBoardScreennew: React.FC = () => {
//   return (
//     <div className="glass">
//       <HomeBanner />
//       <Header />
//       <div className="container-fluid mt-5 ">
//         <div className="d-flex  justify-content-center ">
//           <h1 className="LeaderBoardHeading">LeaderBoard</h1>
//         </div>
//         <div style={{ height: "100px" }}></div>

//         <div className="container  justify-content-center  mt-5">
//           <div className="row LeaderBoard">
//             {LeaderBoardData.map((disorder: any) => (
//               <div
//                 className="  col-sm-12 	 col-xl-4 col-lg-6 col-md-6  card-default-padding"
//                 key={disorder._id}
//               >
//                 <a className="LeaderBoardContainer " href={disorder.link}>
//                   <div className="card border border-2 rounded rounded-4 border-primary border-1 p-5  text-center mb-3">
//                     <div className="ribbon">
//                       <i className="bi  bi-star-fill"></i>
//                     </div>

//                     <div className="card-img-container">
//                       <img
//                         src={disorder.metaldisorderImage}
//                         className="card-img-top"
//                         alt={disorder.mentaldisorderName}
//                       />
//                     </div>
//                     <div className="card-body  mt-5 ">
//                       <h5 className="card-title ">
//                         {disorder.mentaldisorderName}
//                       </h5>
//                     </div>
//                   </div>
//                 </a>
//               </div>
//             ))}
//           </div>
//         </div>
//         <div style={{ height: "200px" }}></div>
//         <div className="leaderBoadGetIn lg-mx-5">
//           <GetinTouchWithUs />
//         </div>
//       </div>

//       <div style={{ height: "50px" }}></div>
//       <Footer />
//     </div>
//   );
// };

// export default LeaderBoardScreennew;

import React from "react";
import HomeBanner from "../../components/HomeBanner/HomeBanner";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import GetinTouchWithUs from "../../components/getInTouchWithUs/GetinTouchWithUs";
import "./LeaderBoardScreennew.css";
import img1 from "../../assets/images/womanInDepression.png";
import img2 from "../../assets/images/womanInThoughtfulPose.png";
import img3 from "../../assets/images/womanSittingAndPointing.png";

const LeaderBoardData = [
  {
    _id: "1",
    link: "/nationalstanding",
    metaldisorderImage: img1,
    mentaldisorderName: "National Standing ",
  },
  {
    _id: "2",
    link: "/zonalStanding",
    metaldisorderImage: img2,
    mentaldisorderName: "Zonal Standing ",
  },
  {
    _id: "3",
    link: "/institutionalStanding",
    metaldisorderImage: img3,
    mentaldisorderName: "Institutional Standing ",
  },
];

const LeaderBoardScreennew: React.FC = () => {
  return (
    <div className="">
      <div className="glass">
        <HomeBanner />
        <Header />
        <div className="container-fluid mt-5">
          <div className="d-flex justify-content-center">
            <h1 className="LeaderBoardHeading">LeaderBoard</h1>
          </div>
          <div style={{ height: "100px" }}></div>

          <div className="container justify-content-center mt-5">
            <div className="row LeaderBoard">
              {LeaderBoardData.map((disorder: any) => (
                <div
                  className="col-sm-12 col-xl-4 col-lg-6 col-md-6 card-default-padding"
                  key={disorder._id}
                >
                  {disorder._id === "1" && (
                    <a className="LeaderBoardContainer" href={disorder.link}>
                      <div className="card border border-2 rounded rounded-4 border-primary border-1 p-5 text-center mb-3">
                        <div className="ribbon">
                          <i className="bi bi-star-fill"></i>
                        </div>
                        <div className="card-img-container">
                          <img
                            src={disorder.metaldisorderImage}
                            className="card-img-top"
                            alt={disorder.mentaldisorderName}
                          />
                        </div>
                        <div className="card-body mt-5">
                          <h5 className="card-title">
                            {disorder.mentaldisorderName}
                          </h5>
                        </div>
                      </div>
                    </a>
                  )}

                  {disorder._id === "2" && (
                    // href={""}
                    <a className="LeaderBoardContainer">
                      <div className="card border border-2 rounded rounded-4 border-primary border-1 p-5 text-center mb-3">
                        <div className="ribbon">
                          <i className="bi bi-star-fill"></i>
                        </div>
                        <div className="card-img-container">
                          <img
                            src={disorder.metaldisorderImage}
                            className="card-img-top"
                            alt={disorder.mentaldisorderName}
                          />
                        </div>
                        <div className="card-body ">
                          <h5 className="card-title">
                            <div className="dropdown">
                              <button
                                className="btn btn-secondary dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {disorder.mentaldisorderName}
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="/zonalOption1"
                                  >
                                    Zonal Option 1
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="/zonalOption2"
                                  >
                                    Zonal Option 2
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="/zonalOption3"
                                  >
                                    Zonal Option 3
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </h5>
                        </div>
                      </div>
                    </a>
                  )}

                  {disorder._id === "3" && (
                    <a className="LeaderBoardContainer" href={disorder.link}>
                      <div className="card border border-2 rounded rounded-4 border-primary border-1 p-5 text-center mb-3">
                        <div className="ribbon">
                          <i className="bi bi-star-fill"></i>
                        </div>
                        <div className="card-img-container">
                          <img
                            src={disorder.metaldisorderImage}
                            className="card-img-top"
                            alt={disorder.mentaldisorderName}
                          />
                        </div>
                        <div className="card-body mt-5">
                          <h5 className="card-title">
                            {disorder.mentaldisorderName}
                          </h5>
                        </div>
                      </div>
                    </a>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div style={{ height: "200px" }}></div>
          <div className="leaderBoadGetIn lg-mx-5">
            <GetinTouchWithUs />
          </div>
        </div>

        <div style={{ height: "50px" }}></div>
        <Footer />
      </div>
    </div>
  );
};

export default LeaderBoardScreennew;
