import { useState, useRef, useEffect } from "react";
import "./InstitutePortal.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import CircularProgressBar from "../../components/CircularProgressBar/CircularProgressBarProps";
import LineChart from "../../components/LineChart/LineChart";
import BarChart from "../../components/BarChart/BarChart";
import "./InstitutePortal.css";
const InstitutePorta = () => {
  const [number, setNumber] = useState("");
  const [feedback, setFeedback] = useState("");
  const feedbackTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [name, setName] = useState("");
  const [parnumber, setParnumber] = useState("");
  const [message, setMessage] = useState("");
  const [Connectwithexpertfeedback, setConnectwithexpertfeedback] =
    useState("");

  const expertFeedbackTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [date, setDate] = useState("");
  const [eventMessage, setEventMessage] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");
  const handleSelect = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    option: string
  ) => {
    event.preventDefault();
    setSelectedEvent(option); // Set the selected event
  };
  const [connectEventfeedback, setConnectEventfeedback] = useState("");
  const eventFeedbackTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [sosShow, setSosShow] = useState(false);
  const sosNotification = () => {
    if (sosShow === true) {
      setSosShow(false);
    } else {
      setSosShow(true);
    }
  };
  const handleSosClick = () => {
    window.open("/emergency", "_blank");
  };

  const handleSubmitCall = (e: any) => {
    e.preventDefault();
    if (number.trim() === "" || number.length !== 10) {
      alert("Please enter your number");
      return;
    }
    setFeedback("Thank you! Our team will connect with you soon.");
    console.log("Mobile Number:", number);
    if (feedbackTimeoutRef.current) {
      clearTimeout(feedbackTimeoutRef.current);
    }

    // Set a timeout to clear the feedback message after 5 seconds
    feedbackTimeoutRef.current = setTimeout(() => {
      setFeedback("");
    }, 5000);
  };

  const handleEventClick = (e: any) => {
    e.preventDefault();
    if (!date || isNaN(new Date(date).getTime())) {
      alert("Please enter a valid date");
      return;
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Remove the time part to compare only the date
    const selectedDate = new Date(date);

    if (selectedDate <= today) {
      alert("Please select a future date");
      return;
    }
    if (eventMessage.trim() === "") {
      alert("Please enter eventMessage");
      return;
    }
    if (!selectedEvent) {
      alert("Please select an event");
      return;
    }

    setConnectEventfeedback(
      "Thank you very much for providing your details. Our expert will be in touch with you shortly."
    );
    console.log(date + " " + eventMessage + " " + selectedEvent);
    if (eventFeedbackTimeoutRef.current) {
      clearTimeout(eventFeedbackTimeoutRef.current);
    }

    // Set a timeout to clear the expert feedback message after 5 seconds
    eventFeedbackTimeoutRef.current = setTimeout(() => {
      setConnectEventfeedback("");
    }, 5000);
  };

  const Connectwithexpert = (e: any) => {
    e.preventDefault();
    if (parnumber.trim() === "" || parnumber.length !== 10) {
      alert("Please enter your number");
      return;
    }
    if (name.trim() === "" || name.length === 0) {
      alert("Please enter your number");
      return;
    }
    if (message.trim() === "" || message.length === 0) {
      alert("Please enter your number");
      return;
    }
    setConnectwithexpertfeedback(
      "Thank you very much for providing your details. Our expert will be in touch with you shortly."
    );
    console.log(parnumber, name, message);

    if (expertFeedbackTimeoutRef.current) {
      clearTimeout(expertFeedbackTimeoutRef.current);
    }

    // Set a timeout to clear the expert feedback message after 5 seconds
    expertFeedbackTimeoutRef.current = setTimeout(() => {
      setConnectwithexpertfeedback("");
    }, 5000);
  };

  const [currentPercentage, setCurrentPercentage] = useState(0);
  const averageMarks = 50;
  useEffect(() => {
    // Function to increment the percentage gradually
    const increment = () => {
      setCurrentPercentage((prev) => {
        if (prev < averageMarks) {
          return Math.min(prev + 1, averageMarks); // Increase by 1 each time until it reaches averageMarks
        } else {
          clearInterval(timer); // Stop the interval when we reach averageMarks
          return prev;
        }
      });
    };

    const timer = setInterval(increment, 100); // Set speed of animation (20 ms in this case)

    return () => clearInterval(timer); // Cleanup interval on component unmount
  }, [averageMarks]);
  return (
    <div>
      <div>
        <Header />
        <div className="container">
          <div className="row my-4 ">
            <div className="col-md-8 col-12 mt-5">
              <div className="row">
                <div className="col-md-10 col-12 ">
                  <h1 className="d-flex justify-content-center">
                    <div className="border border-2 p-1 w-100 border-primary  rounded-4 ">
                      <div className="">
                        <div className="d-flex justify-content-end">
                          <input
                            type="text"
                            className="Search-input  w-100"
                            placeholder="Search by name or ID..."
                          />
                          <span className="input-group-text ">
                            <i className="bi bi-search"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </h1>
                </div>
                {/* <div
                  className="text-center d-flex justify-content-cennter col-2"
                  onClick={sosNotification}
                >
                  <i className="circle-bell  bi bi-bell-fill"></i>
                </div>
                <div className="text-center d-flex justify-content-center col-2">
                  <button
                    className="btn btn-danger btn-sm  "
                    onClick={handleSosClick}
                  >
                    SOS Button
                  </button>
                </div> */}
              </div>
            </div>

            <div className="institute-hero col-md-4 col-12 container ">
              <div className="d-flex justify-content-end  ">
                <div className="circle ">
                  <span className="institute-angel">
                    <a className="institute-angle-title" href="/instituteHero">
                      INSTITUTE HERO
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="container text-center">
            <div className="col-12 ">
              <div className="row d-flex justify-content-center ">
                <div className="d-flex justify-content-center  ">
                  <div className="col-6">
                    <div
                      className="btn btn-danger btn-lg  text-center"
                      onClick={handleSosClick}
                    >
                      SOS Button
                    </div>
                  </div>

                  <div
                    className="text-center d-flex justify-content-cennter col-6"
                    onClick={sosNotification}
                  >
                    <i className="circle-bell  bi bi-bell-fill"></i>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div style={{ height: "50px" }}></div>

          <div className=" institute-portal-section-contact just mb-5  mt-5">
            <div className="row">
              <h2 className="section-title mb-5"> Academic Meter</h2>
            </div>

            <div className="row d-flex justify-content-center text-center ">
              <div className="card  col-md-6 col-11 mb-3">
                <div className=" w-100 p-5">
                  <BarChart />
                </div>
              </div>
              <div className="card graph-institute-portal col-md-6 col-11 mb-3">
                <div className=" w-100 py-5 my-5">
                  <h5>Data</h5>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center text-center">
              <div className="card graph-institute-portal col-md-6 col-11 mb-3">
                <div className=" w-100 py-5 my-5">
                  <LineChart />
                </div>
              </div>
              <div className="card graph-institute-portal col-md-6 col-11 mb-3">
                <div className=" w-100 py-5 my-5">
                  <h5>Data</h5>
                </div>
              </div>
            </div>
          </div>

          <div style={{ height: "100px" }}></div>
          <div className=" institute-portal-section-contact just mb-5  mt-5">
            <div className="row">
              <h2 className="section-title mb-5"> Skill Meter</h2>
            </div>

            <div className="row d-flex justify-content-center text-center ">
              <div className="card pb-5 piechart-GuardianPortal col-md-6 col-12 mb-3">
                <div className="circular-progress-bar w-100 ">
                  <CircularProgressBar percentage={currentPercentage} />
                </div>
              </div>
              <div className="card graph-institute-portal col-md-6 col-11 mb-3">
                <div className=" w-100 py-5 my-5">
                  <h5>Data</h5>
                </div>
              </div>
            </div>
          </div>

          <div style={{ height: "100px" }}></div>

          <div className=" my-5">
            <div className="institute-portal-section-contact mb-5 ">
              <h2 className="section-title">Connect</h2>
              <div className="row gap-3  mb-5 justify-content-center ">
                <div className="col-12 col-md-5  ">
                  <div className="card text-center ">
                    <div className="card-body">
                      <p>
                        <i className="bi bi-telephone-fill m-2"></i>CALL BACK
                      </p>
                      <div className="d-flex justify-content-center">
                        <input
                          type="number"
                          value={number}
                          onChange={(e) => setNumber(e.target.value)}
                          className="w-75  form-control mb-2  "
                          placeholder="Enter your number"
                          required
                        />
                      </div>
                      <button
                        className="btn connect-expert-button w-50   mb-3"
                        onClick={handleSubmitCall}
                      >
                        OK
                      </button>
                      {feedback && (
                        <p className="alert alert-success">{feedback}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-5">
                  <div className="card  text-center ">
                    <div className="card-body">
                      <i className="bi bi-chat-dots-fill  m-2 "></i>Chat with Us
                      <div className="text-center">
                        <button className=" btn connect-expert-button w-50 m-5">
                          Open Chat
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* </div> */}

              <div className=" justify-content-center m-md-3 ">
                <div className="card   m-md-5">
                  <div className="row d-flex   m-md-3 p-1 p-md-5  connect-expert-container">
                    <h3 className="text-center text-bold mb-5">
                      Connect With Our Team
                    </h3>
                    <div className="col-12 col-lg-6 text-center mb-5">
                      <img
                        className="team-image"
                        // className="w-md-100 w-100 connect-expert-img"
                        src="https://www.foqas.com/static/web/img/tech/techvector.png"
                        alt=""
                        // style={{ height: "250px", width: "auto" }}
                        // style={{
                        //   height: "250px",
                        //   maxWidth: "100%",
                        //   width: "auto",
                        //   objectFit: "cover",
                        // }}
                      />
                    </div>

                    <div className="col-12 col-lg-6 mb-2">
                      <div style={{ height: "320px", width: "auto" }}>
                        <div className="row mb-2">
                          {/* <div className="col-12 col-md-3  connect-expert-label">
                            Name:
                          </div> */}
                          <div className="col-12 col-lg-9  w-100">
                            <input
                              type="text"
                              placeholder="Name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              required
                              className="form-control connect-expert-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-2">
                          {/* <div className="col-12 col-md-3 connect-expert-label">
                            Mobile:
                          </div> */}
                          <div className="col-12 col-lg-9  w-100">
                            <input
                              type="number"
                              placeholder="Contact Number"
                              value={parnumber}
                              onChange={(e) => setParnumber(e.target.value)}
                              required
                              className="form-control  connect-expert-input"
                            />
                          </div>
                        </div>

                        <div className="row">
                          {/* <textarea
                            className="form-control  connect-expert-textarea"
                            placeholder="Reason for Connection"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                          ></textarea> */}
                          <div className="text-center   d-flex justify-content-center mb-md-2 mt-0 mb-0 ">
                            <textarea
                              className="form-control w-md-100  connect-expert-textarea"
                              placeholder="Reason to connect with our team"
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                              required
                            ></textarea>
                          </div>
                        </div>

                        <button
                          className="w-100   connect-expert-button"
                          onClick={Connectwithexpert}
                        >
                          {/* Connect with Expert */}
                          Connect with our team
                        </button>

                        {Connectwithexpertfeedback && (
                          <p className="alert alert-success">
                            {Connectwithexpertfeedback}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ height: "100px" }}></div>

          <div className=" my-5">
            <div className=" institute-portal-section-contact  mb-5 ">
              <div className="mb-5">
                <h3 className="text-center text-bold">
                  Book a Special Event or Activity
                </h3>
              </div>

              {/* code  */}
              <div className="justify-content-center m-md-3  ">
                <div className="card m-md-5">
                  <div className="row d-flex   m-md-3 p-1 p-md-5    connect-expert-container">
                    <div className="col-12 col-lg-7 text-center mb-5 ">
                      <img
                        // className="w-md-100 w-100 connect-expert-img"
                        className="event-image "
                        src="https://www.agilitypr.com/wp-content/uploads/2020/08/event-1.jpg"
                        alt=""
                        // style={{
                        //   height: "320px",
                        //   maxWidth: "100%",
                        //   objectFit: "cover",
                        // }}
                      />
                    </div>

                    <div className="col-12 col-lg-5 mb-2">
                      <div style={{ height: "350px", width: "auto" }}>
                        <div className="row mb-2">
                          <div className="col-12 col-lg-9  w-100 ">
                            <input
                              className="form-control"
                              type="date"
                              value={date}
                              onChange={(e) => setDate(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col-12 col-lg-9   w-100">
                            <div className="dropdown ">
                              <button
                                className="btn connect-expert-button dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {selectedEvent ? selectedEvent : "Choose event"}
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <li>
                                  <button
                                    className="dropdown-item"
                                    onClick={(e: any) =>
                                      handleSelect(e, "Workshops and Seminars")
                                    }
                                  >
                                    Workshops and Seminars
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="dropdown-item"
                                    onClick={(e: any) =>
                                      handleSelect(e, "Cultural Festivals")
                                    }
                                  >
                                    Cultural Festivals
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="dropdown-item"
                                    onClick={(e: any) =>
                                      handleSelect(e, "Academic Conferences")
                                    }
                                  >
                                    Academic Conferences
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="dropdown-item"
                                    onClick={(e: any) =>
                                      handleSelect(e, "Other")
                                    }
                                  >
                                    Other
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="text-center d-flex justify-content-center  mb-md-2 mt-0 mb-0 ">
                            <textarea
                              className="form-control w-md-100  connect-expert-textarea"
                              placeholder="Reason to connect with an expert"
                              value={eventMessage}
                              onChange={(e) => setEventMessage(e.target.value)}
                              required
                            ></textarea>
                          </div>
                        </div>

                        <button
                          className="w-100 connect-expert-button"
                          onClick={handleEventClick}
                        >
                          Curate an Event
                        </button>

                        {connectEventfeedback && (
                          <p className="alert alert-success">
                            {connectEventfeedback}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ height: "100px" }}></div>
          <div className=" my-5">
            <div className="institute-portal-section-contact">
              <h2 className="section-title">Activities and Events</h2>
              <p>
                Upcoming activities and events relevant to the ward's
                development.
              </p>
            </div>
          </div>
          <div style={{ height: "50px" }}></div>

          <div className=" my-5 text-center ">
            <div className="row mb-5 text-center">
              <div className="col-md-6 col-12">
                <button
                  className="btn btn-danger btn-lg w-50 text-center"
                  onClick={handleSosClick}
                >
                  SOS Button
                </button>
              </div>
              <div className="col-md-6 col-12">
                <button
                  className="btn btn-info btn-lg w-25 text-center"
                  onClick={sosNotification}
                >
                  <i className=" bi bi-bell-fill"></i>
                </button>
              </div>
              {sosShow && (
                <div>
                  <div className="modalOverlay" onClick={sosNotification}>
                    <div className="modal" onClick={(e) => e.stopPropagation()}>
                      <button
                        className="modalCloseButton"
                        onClick={sosNotification}
                      >
                        X
                      </button>
                      <h1> All alert hello</h1>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* <h1>gds</h1>
          <div className=" ">
            <div className="row d-flex "></div>
            <div className="row">
              <div className="col-6">
                <h1 className="d-flex ">
                  <div className="border border-2 p-1 w-100 border-primary  rounded-4 ">
                    <div className="">
                      <div className="d-flex justify-content-end">
                        <input
                          type="text"
                          className="Search-input  w-100"
                          placeholder="Search by name or ID..."
                        />
                        <span className="input-group-text ">
                          <i className="bi bi-search"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </h1>
              </div>
              <div className="col-3">
                <button
                  className="btn btn-danger btn-lg "
                  onClick={handleSosClick}
                >
                  SOS
                </button>
              </div>
              <div className="col-2">
                <div
                  className="text-center d-flex justify-content-cennter"
                  onClick={sosNotification}
                >
                  <i className="circle-bell-new  bi bi-bell-fill"></i>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default InstitutePorta;
