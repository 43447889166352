import { useState, useEffect } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import counsalrImage from "../../assets/images/counselarNew.png";
import "./CounsellorScreen.css";
import { GetAllCouncelors } from "../../store/interface";
import { GetCouncellors } from "../../api/councellor";
import Swal from "sweetalert2";
import Loader from "../../components/Loader/Loader";
import { AxiosError } from "axios";

const initialCouncellors: GetAllCouncelors = {
  statusCode: 0,
  data: [],
  message: "",
  isSuccessful: false,
};
const CounselorScreen = () => {
  const [councellorsdata, setCouncellorsdata] =
    useState<GetAllCouncelors>(initialCouncellors);
  const [errors, setErrors] = useState<string | null>(null);

  useEffect(() => {
    const fetchCouncellorsdata = async () => {
      try {
        debugger;
        setLoader(true);
        const response = await GetCouncellors();
        setCouncellorsdata(response.data);

        setLoader(false);
      } catch (error) {
        debugger;
        setLoader(false);

        let _errorMsg: string = "";
        if (error instanceof AxiosError) {
          setErrors(error.message);
          _errorMsg = error.message;
        } else {
          setErrors("An unknown error occurred");
          _errorMsg = "Something went wrong! Try after sometime";
        }

        console.error("Error fetching leaderboard data:", error);
      }
    };

    fetchCouncellorsdata();
  }, []);
  const [loader, setLoader] = useState<boolean>(false);

  return (
    <div>
      {loader && <Loader />}
      <Header />

      <div className="container">
        <h1 className="text-center">Counselor Screen</h1>

        <div className="counselloScreen d-flex row ">
          {councellorsdata.data.map((counsellor) => (
            <div
              key={counsellor.id}
              //    col-xs-12 col-sm-6 col-md-4 col-lg-3
              className="counselloScreen_body card text-center col-6"
            >
              <img
                src={counsalrImage}
                alt={counsellor.name}
                className="counselloScreen_counsellorImg"
              />

              <p className="counselloScreen_counsellorName">
                {counsellor.name}
              </p>

              <button className="counselloScreen_joinButton">Join</button>

              {/* <div className="">
                  <img
                    src={counsellor.counsalrRating}
                    alt={counsellor.counsalrName}
                    className="counsellorRating_new"
                  />
                </div> */}
              <div className="counselloScreen_counsellorRating">
                <i className="starfill bi bi-star-fill"></i>
                <i className="starfill bi bi-star-fill"></i>
                <i className="starfill bi bi-star-fill"></i>
                <i className="starfill bi bi-star-fill"></i>
                <i className="starfill bi bi-star-fill"></i>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CounselorScreen;
