import React from "react";

const MentalHealth = () => {
  return (
    <div>
      <h1>mentalHealthClick</h1>
    </div>
  );
};

export default MentalHealth;
